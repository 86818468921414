<template>
    <div class="d-flex flex-column flex-grow-1">
      <section class="pb-5">
        <div class="container">
          <div class="row">


            <div class="col d-flex align-items-center">
              <div class="d-inline-block p-3 p-md-4 rounded-circle">
                <img src="../assets/img/kljukica_zelena.svg" alt="Hostomate logo" class="icon">
              </div>
              <div class="ml-4">
                <h1 class="mb-2">
                  Self Check-in
                </h1>
                <div class="lead main-description">
                  After successfully completing the check-in process, you will receive a code to access the property and the room, along with all the instructions.
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section class="bg-light tab-content pt-5 flex-grow-1">
        <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
          <div class="container">

            <div class="row mb-4 mb-md-5 align-items-start">
              <div class="col-lg-8 border-lg-right pr-lg-5">
                <ValidationObserver v-slot="{ handleSubmit, errors }" ref="form">
                  <form @submit.prevent="handleSubmit(login)">

                    <div class="form-group">
                      <label>Authenticate by entering the last 4 digits of the reservation holder's phone number:</label>
                      <ValidationProvider name="phone number" vid="booking.payer.phone.number" rules="required" v-slot="{ errors }">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              {{ $store.state.booking.payer.phone.number }}
                            </span>
                          </div>
                          <input class="form-control" :class="{ 'is-invalid': errors[0] }" v-model="booking.payer.phone.number">
                        </div>

                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>


                    <button class="btn btn-primary" type="submit">Login</button>

                  </form>
                </ValidationObserver>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>

<script>
  import Vue from "vue";
  import { VueReCaptcha } from 'vue-recaptcha-v3';
  Vue.use(VueReCaptcha, { siteKey: "6Lc0JGcaAAAAADH9zLx_zNr50SRtO61dnO85JBF8" });

  export default {
    name: "Login",
    data: () => ({
      booking: {
        _id: "",
        payer: {
          phone: {
            number: ""
          }
        }
      },
    }),
    created(){
      this.booking._id = this.$route.params.bookingId;
    },
    methods: {
      login() {
        let payload = {
          "booking": this.booking
        }
        console.log(payload)
        this.$recaptcha("login")
        .then((token) => {
          payload["g-recaptcha-response"] = token;
          return fetch( process.env.VUE_APP_API + '/bookings/phone', {
            method : 'post',
            credentials: 'include',
            headers: {
              "Content-type": "application/json",
              "Accept": "application/json",
              "Accept-Charset": "utf-8",
              'CSRF-Token': this.$csrf
            },
            body: JSON.stringify(payload)
          })
        })
        .then(response => response.json())
        .then(response => {
          if(!response.error){
            //success
            this.$router.go(this.$router.currentRoute)
          }else{
            console.log(response.error);
            this.$refs.form.setErrors(response.error.messages);
          }
        });
      }
    }
  }
</script>

<style scoped></style>
